import { useEffect, useState } from "react";
import {
  ActionItemInfo,
  TriggeringReportInfo,
} from "../../../system/types/ActionItem";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  createFilterOptions,
  Dialog,
  styled,
  TextField,
} from "@mui/material";
import moment from "moment";

interface SelectTriggeringReportProps {
  data: ActionItemInfo;
  setData: (data: ActionItemInfo) => void;
  value: TriggeringReportInfo | null;
  setValue: (value: TriggeringReportInfo | null) => void;
}

function validation(dialogValue: TriggeringReportInfo) {
  if (dialogValue.title === "") {
    AlertModal("check", "A/I발생 회의명을 작성해 주세요.");
    return false;
  } else if (!dialogValue.reportDate) {
    AlertModal("check", "A/I발생일을 선택해 주세요.");
    return false;
  }
  return true;
}

const filter = createFilterOptions<TriggeringReportInfo>();
function SelectTriggeringReport({
  setData,
  data,
  value,
  setValue,
}: SelectTriggeringReportProps) {
  const [reportData, setReportData] = useState<TriggeringReportInfo[]>([]);
  function refreshData() {
    ActionItemsApi.getTriggeringReportsList()
      .then((res) => {
        const sortedData = res.data.sort(
          (a: { reportDate: string }, b: { reportDate: string }) =>
            new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
        );

        // 정렬된 데이터를 setReportData에 설정
        setReportData(sortedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshData();
  }, []);

  const [toggleOpen, setToggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: "",
    reportDate: "",
  });

  const handleClose = () => {
    setDialogValue({
      title: "",
      reportDate: "",
    });
    setToggleOpen(false);
  };
  var submitcheck = false;
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (submitcheck === false && validation(dialogValue)) {
      submitcheck = true;
      ActionItemsApi.createTriggeringReports({
        ...dialogValue,
        reportDate:
          moment(new Date(dialogValue.reportDate)).format("YYYY-MM-DD") +
          " 00:00:00",
      })
        .then((res) => {
          setValue({
            title: dialogValue.title,
            reportDate: moment(dialogValue.reportDate).format("YYYY-MM-DD"),
          });
          setData({ ...data, triggeringReportId: res.data.id });
          handleClose();
          refreshData();
          submitcheck = false;
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        });
    }
  };

  return (
    <>
      <Autocomplete
        value={value}
        freeSolo
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setToggleOpen(true);
              setDialogValue({
                title: newValue,
                reportDate: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            setToggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              reportDate: "",
            });
          } else {
            setValue(newValue);
            setData({
              ...data,
              triggeringReportId: newValue?.id ? newValue?.id : null,
            });
          }
        }}
        options={reportData}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `"${params.inputValue}" 추가`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => (
          <li {...props} key={option.id || option.title}>
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <CustomAITextField
            {...params}
            placeholder="제목 검색 후 선택해 주세요."
          />
        )}
        style={{ width: "100%", minWidth: "200px" }}
      />
      <Dialog open={toggleOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>A/I발생 회의 추가</DialogTitle>
          <DialogContent>
            <DialogContentText>
              A/I이 발생한 회의를 추가해주세요.
            </DialogContentText>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              id="title"
              value={dialogValue.title}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  title: event.target.value,
                })
              }
              label="A/I발생 회의명"
              type="text"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              id="name"
              value={dialogValue.reportDate}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  reportDate: event.target.value,
                })
              }
              label="A/I발생일"
              type="date"
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>취소</Button>
            <Button type="submit">저장</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default SelectTriggeringReport;
const CustomAITextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    padding: "3px 7px !important",
    color: "#000000",
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
});

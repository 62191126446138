import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItemText,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
} from "@mui/material";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  EditDropDownListComponentBox,
  AITableContent,
  AITableTitle,
  StyledTextarea,
  TableFixedHeight,
} from "../../../styles/theme";
import { CustomText } from "../../../styles/CustomText";
import { useEffect, useState } from "react";
import { Brand600, Gray500, Gray700 } from "../../../styles/Colors";
import {
  ActionItemInfo,
  BasicInfo,
  ManagerInfo,
  OrganizationInfo,
  TriggeringReportInfo,
} from "../../../system/types/ActionItem";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ACTIONITEM_PRIORITY } from "../../../system/Constants";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../../system/ApiService";
import moment from "moment";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import AlertModal from "../../Common/AlertModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SelectManager from "./SelectManager";
import SelectTriggeringReport from "./SelectTriggeringReport";
import InfoIcon from "@mui/icons-material/Info";
interface ActionItemEditProps {
  data: ActionItemInfo;
  setData: (data: ActionItemInfo) => void;

  teamData: OrganizationInfo[];
  organizationunitName?: string | null;
  previousState: boolean;
}

function ActionItemEdit({
  data,
  setData,
  teamData,
  organizationunitName,
  previousState,
}: ActionItemEditProps) {
  const [categoryData, setCategoryData] = useState<BasicInfo[]>([]);
  const [value, setValue] = useState<TriggeringReportInfo | null>(null);
  const onChange = (args: any) => {
    const value = args.target.value;
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: value,
    });
  };
  const onChangeCheck = (args: any) => {
    const value = args.target.value;
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]:
        value === "true" ? true : value === "false" ? false : value,
    });
  };

  const onChangeDate = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: moment(
        args.target.value
      ).format("YYYY-MM-DD"),
    });
  };
  useEffect(() => {
    ActionItemsApi.getAICategoryList()
      .then((res) => {
        const etcItem = res.data.filter(
          (item: BasicInfo) => item.name === "기타"
        ); // "기타"인 항목만 필터링
        const otherItems = res.data.filter(
          (item: BasicInfo) => item.name !== "기타"
        ); // 나머지 항목들

        // 기타 항목을 리스트의 마지막으로 배치
        setCategoryData([...otherItems, ...etcItem]);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChangeManager = (userList: ManagerInfo[]) => {
    if (userList) {
      setData({ ...data, managers: userList });
    }
  };

  const onClickChip = (id: number) => {
    const newList = data.managers;
    var emp = data.managers.findIndex((x) => x.manager.id === id);
    var state = newList[emp].isPrimaryManager;
    if (emp !== -1) {
      newList[emp] = { ...newList[emp], isPrimaryManager: !state };
    }
    setData({ ...data, managers: newList });
  };

  let fields: Object = {
    dataSource: teamData,
    value: "id",
    text: "name",
    child: "children",
  };

  const levelZeroIds = teamData
    .filter((item) => item.level === 0)
    .map((item) => item.id);

  const onChangeTeam = (args: any) => {
    if (args.value && args.value.length > 0 && args.value[0]) {
      const checkIdExists = (id: number) => levelZeroIds.includes(id);

      if (checkIdExists(Number(args.value[0]))) {
        AlertModal("msg", "최상위 조직은 선택하실 수 없습니다.");
        setData({ ...data, organizationUnitId: null });
      } else {
        setData({
          ...data,
          organizationUnitId: Number(args.value[0]),
        });
      }
    } else {
      setData({ ...data, organizationUnitId: null });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const selectedValue = Number(value);

    // 체크박스가 체크되었으면 추가, 체크 해제되었으면 제거
    const newCategoryIds = checked
      ? [...data.categoryIds, selectedValue]
      : data.categoryIds.filter((id) => id !== selectedValue);

    setData({
      ...data,
      categoryIds: newCategoryIds,
    });
  };

  const [advancedSetting, setAdvancedSetting] = useState(false);

  const onChangeSettingState = () => {
    setAdvancedSetting(!advancedSetting);
  };
  return (
    <Grid container item xs={12} mt="20px">
      {/* 시작 보고 */}

      {previousState === false && (
        <>
          <Grid container item xs={12}>
            <CustomText type="subtitle" bold600 color={Gray700}>
              A/I 작성
            </CustomText>
          </Grid>
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="5px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                A/I발생 회의명
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <SelectTriggeringReport
                data={data}
                setData={setData}
                value={value}
                setValue={setValue}
              />
            </AITableContent>
          </TableFixedHeight>

          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="3px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                A/I발생일
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <CustomText type="body" marginLeft="10px">
                {value?.reportDate
                  ? moment(value.reportDate).format("YYYY-MM-DD")
                  : "A/I발생 회의를 선택해주세요."}
              </CustomText>
            </AITableContent>
          </TableFixedHeight>
        </>
      )}
      <TableFixedHeight container item xs={12} alignItems="center" mb="5px">
        <AITableTitle
          item
          xs={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            제목
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={10} pl="20px" container alignItems="center">
          <TextBoxComponent
            placeholder="제목을 입력해주세요."
            cssClass="e-outline"
            name="title"
            onChange={onChange}
            value={data.title}
          ></TextBoxComponent>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} alignItems="center" mb="7px">
        <AITableTitle
          item
          xs={4}
          sm={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            카테고리
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} sm={10} pl="20px" container>
          <FormControl>
            <FormGroup row>
              {categoryData.map((dt: BasicInfo, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={data.categoryIds.includes(dt.id)}
                      onChange={handleCheckboxChange}
                      value={dt.id}
                      sx={{
                        "&.Mui-checked": {
                          color: Brand600, // 체크된 상태의 색상
                        },
                      }}
                    />
                  }
                  label={
                    <ListItemText
                      primary={dt.name}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  }
                  sx={{ width: "100px" }} // 각 체크박스 사이 간격 설정
                />
              ))}
            </FormGroup>
          </FormControl>
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight container item xs={12} alignItems="center" mb="7px">
        <AITableTitle
          item
          xs={2}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            내용
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={10} pl="20px" container alignItems="center">
          <StyledTextarea
            value={data.content}
            onChange={onChange}
            placeholder="내용을 입력해주세요."
            name="content"
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mt="2px"
        mb="2px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            A/I수행 주관부서
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container>
          <DropDownTreeComponentBox
            cssClass="e-outline"
            colorvalue={data.organizationUnitId ? "#000000" : "#B2B4B8"}
            change={onChangeTeam}
            fields={fields}
            changeOnBlur={false}
            placeholder={
              data.organizationUnitId && organizationunitName
                ? organizationunitName
                : "작성부서를 선택해 주세요."
            }
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mt="-3px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            A/I 담당자
          </CustomText>
          <Tooltip
            title={"정담당자는 반드시 선택해야 합니다."}
            placement="bottom"
          >
            <InfoIcon fontSize="small" color="action" />
          </Tooltip>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <SelectManager
            list={data.managers}
            onChange={onChangeManager}
            onClickChip={onClickChip}
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="3px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            완료 예정일
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <DatePickerComponent
            placeholder="목표 완료일을 입력해 주세요."
            name="dueDate"
            format="yyyy-MM-dd"
            value={data.dueDate ? new Date(data.dueDate) : undefined}
            onChange={onChangeDate}
            cssClass="e-outline"
            style={{ height: "38px" }}
          />
        </AITableContent>
      </TableFixedHeight>
      <TableFixedHeight
        container
        item
        xs={12}
        sm={6}
        alignItems="center"
        mb="7px"
      >
        <AITableTitle
          item
          xs={4}
          container
          justifyContent="right"
          alignItems="center"
        >
          <CustomText type="subtitle" lineSpace color={Gray500}>
            우선순위
          </CustomText>
        </AITableTitle>
        <AITableContent item xs={8} pl="20px" container alignItems="center">
          <EditDropDownListComponentBox
            cssClass="e-outline"
            name="priority"
            onChange={onChange}
            value={data.priority}
            dataSource={ACTIONITEM_PRIORITY}
            placeholder="우선순위를 선택해 주세요."
          />
        </AITableContent>
      </TableFixedHeight>
      <Grid
        container
        item
        xs={12}
        style={{ cursor: "pointer" }}
        onClick={onChangeSettingState}
        mt="20px"
      >
        <CustomText type="subtitle" bold600 color={Gray700} cursorPointer>
          고급 설정
        </CustomText>
        {advancedSetting ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Grid>
      {advancedSetting && (
        <>
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="3px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                비밀글
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <RadioGroup
                row
                onChange={onChangeCheck}
                value={data.isSecret}
                name="isSecret"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="default" />}
                  label="설정"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="default" />}
                  label="설정 안 함"
                />
              </RadioGroup>
            </AITableContent>
          </TableFixedHeight>
          <TableFixedHeight
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            mb="7px"
          >
            <AITableTitle
              item
              xs={4}
              container
              justifyContent="right"
              alignItems="center"
            >
              <CustomText type="subtitle" lineSpace color={Gray500}>
                긴급
              </CustomText>
            </AITableTitle>
            <AITableContent item xs={8} pl="20px" container alignItems="center">
              <RadioGroup
                row
                onChange={onChange}
                value={data.isUrgent}
                name="isUrgent"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="default" />}
                  label="설정"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="default" />}
                  label="설정 안 함"
                />
              </RadioGroup>
            </AITableContent>
          </TableFixedHeight>
        </>
      )}
    </Grid>
  );
}

export default ActionItemEdit;

interface DropDownListComponentProps {
  colorvalue?: string;
}

const DropDownTreeComponentBox = styled(DropDownTreeComponent)(
  ({ colorvalue }: DropDownListComponentProps) => ({
    width: "100%",
    height: "38px",
    fontSize: "14px",
    boxShadow: "none",
    border: "none",
    "&::placeholder": {
      color: colorvalue ? `${colorvalue} !important` : "#B2B4B8 !important",
      fontWeight:
        colorvalue === "#000000" ? "400 !important" : "300 !important",
      fontSize: "14px !important",
      fontFamily: "Noto sans CJK KR",
    },
  })
);

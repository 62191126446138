import { Typography } from "@mui/material";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { useEffect, useMemo, useState } from "react";
import { BasicTable, CustomButton } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import AdminApi from "../../../system/api/AdminApi";
import UserApi from "../../../system/api/UserApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { Roles, UserRole, WorkUser } from "../../../system/types/type";

interface EmployeeAdminModalProps {
  onReloading: (res: UserRole) => void;
}

function EmployeeAdminModal({ onReloading }: EmployeeAdminModalProps) {
  const [userList, setUserList] = useState<WorkUser[]>([]);
  const [data, setData] = useState<UserRole>();
  const [roleList, setRoleList] = useState<Roles[]>([]);
  const pageSettings: PageSettingsModel = { pageSize: 5 };
  const toolbarOptions: ToolbarItems[] = ["Search"];
  const Loading = useLoadingDispatch();
  let dialogInstance: DialogComponent;
  let grid: any;

  useMemo(() => {
    AdminApi.GetRolesList()
      .then((res) => {
        setRoleList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  useEffect(() => {
    UserApi.GetAIUsers("")
      .then((res) => {
        setUserList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  const rowSelected = () => {
    if (grid) {
      const selectedrecords = grid.getSelectedRecords();
      setData({ ...data, user: selectedrecords[0] });
    }
  };

  const buttons: any = [
    {
      buttonModel: {
        content: "저장",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        if (data?.role && data.user) {
          Loading({ type: "LOADING" });
          AdminApi.CreateAdmin(data)
            .then(() => {
              dialogInstance.hide();
              setData({});
              // onReloading(true);
              onReloading(data);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              alert(msg);
            })
            .finally(() => Loading({ type: "COMPLETE" }));
        }
      },
    },
  ];

  const handleClick = () => {
    dialogInstance.show();
  };

  const onChange = (e: any) => {
    if (e.target.value) {
      setData({
        ...data,
        role: roleList.filter((x) => x.displayName === e.target.value)[0],
      });
    }
  };

  return (
    <div>
      <CustomButton onClick={handleClick} iconCss="e-icons e-plus">
        권한 추가
      </CustomButton>
      <DialogComponent
        header="권한 추가"
        width="800px"
        showCloseIcon={true}
        isModal={true}
        buttons={buttons}
        closeOnEscape={false}
        visible={false}
        ref={(dialog) => (dialogInstance = dialog!)}
      >
        <div style={{ maxHeight: "700px" }}>
          <GridComponent
            dataSource={userList}
            height={200}
            allowPaging={true}
            allowFiltering={true}
            pageSettings={pageSettings}
            rowSelected={rowSelected}
            toolbar={toolbarOptions}
            ref={(g) => (grid = g)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="name"
                headerText="이름"
                headerTextAlign="Center"
                textAlign="Center"
                width="100"
              />
              <ColumnDirective
                field="department"
                headerText="파트"
                headerTextAlign="Center"
                textAlign="Center"
                width="100"
              />
              <ColumnDirective
                field="team"
                headerText="팀"
                headerTextAlign="Center"
                textAlign="Center"
                width="100"
              />
              <ColumnDirective
                field="jobTitle"
                headerText="직책"
                headerTextAlign="Center"
                textAlign="Center"
                width="100"
              />
              <ColumnDirective
                field="jobPosition"
                headerText="직위"
                headerTextAlign="Center"
                textAlign="Center"
                width="100"
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, Filter, Page]} />
          </GridComponent>
          <BasicTable>
            <thead
              style={{
                backgroundColor: "#f0f2f5",
                textAlign: "center",
              }}
            >
              <tr>
                <td style={{ fontSize: "14px", padding: "5px" }}>파트</td>
                <td style={{ fontSize: "14px", padding: "5px" }}>팀</td>
                <td style={{ fontSize: "14px", padding: "5px" }}>이름</td>
                <td style={{ fontSize: "14px", padding: "5px" }}>권한 설정</td>
              </tr>
            </thead>
            {data && data.user ? (
              <>
                <tbody style={{ textAlign: "center" }}>
                  <tr>
                    <td>
                      <Typography variant="body2">
                        {data.user.department}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2">{data.user.team}</Typography>
                    </td>
                    <td>
                      <Typography variant="body2">{data.user.name}</Typography>
                    </td>
                    <td>
                      <DropDownListComponent
                        dataSource={roleList.map((x) => x.displayName)}
                        onChange={onChange}
                        placeholder="권한 선택"
                        value={data.role?.displayName}
                        width="200px"
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>선택한 임직원이 없습니다.</tbody>
            )}
          </BasicTable>
        </div>
      </DialogComponent>
    </div>
  );
}

export default EmployeeAdminModal;

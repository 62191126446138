import { Chip, Grid, styled, Tooltip } from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  TreeGridComponent,
  Page,
  Filter,
  Sort,
  Inject,
  FilterSettingsModel,
} from "@syncfusion/ej2-react-treegrid";
import { useCallback, useRef } from "react";
import {
  ActionItemDetailInfo,
  BasicInfo,
  ManagerInfo,
} from "../../system/types/ActionItem";
import { AIRoutes } from "../../system/types/type";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ACTIONITEM_STATUS_ALL } from "../../system/Constants";
import {
  BaseBlack,
  ErrorDefault,
  Gray100,
  Green500,
  SuccessDefault,
} from "../../styles/Colors";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import { SECONDARY } from "../../styles/theme";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { L10n } from "@syncfusion/ej2-base";
import { useUserState } from "../../system/context/UserContext";

interface ActionItemTableProps {
  actionItemList: ActionItemDetailInfo[];
  refreshData: () => void;
}
L10n.load({
  ko: {
    grid: {
      OKButton: "적용",
      CancelButton: "취소",
      StartsWith: "시작하는 문자",
      EndsWith: "끝나는 문자",
      Contains: "포함",
      Equal: "같음",
      NotEqual: "같지 않음",
      LessThan: "보다 적은",
      LessThanOrEqual: "보다 같거나 적은",
      GreaterThan: "보다 많은",
      GreaterThanOrEqual: "보다 같거나 많은",
      ChooseDate: "날짜를 선택해주세요.",
      EnterValue: "검색어를 입력해주세요.",
      SelectAll: "모두 선택",
      Blanks: "비어있음",
      NoResult: "결과가 없습니다.",
      ClearFilter: "초기화",
      TextFilter: "문구 필터",
      NumberFilter: "숫자 필터",
      DateFilter: "날짜 필터",
      DateTimeFilter: "날짜 시간 필터",
      Between: "사이에",
      CustomFilter: "사용자 정의 필터",
      CustomFilterPlaceHolder: "검색어를 입력해주세요.",
      CustomFilterDatePlaceHolder: "날짜를 선택해주세요.",
      AND: "그리고",
      OR: "또는",
      ShowRowsWhere: "다음 조건의 행을 표시",
      MatchCase: "대소문자 구분",
      SortAtoZ: "오름차순",
      SortZtoA: "내림차순",
      SortByOldest: "오래된 순",
      SortByNewest: "최신 순",
      AddCurrentSelection: "필터에 현재 선택 항목 추가",
      Search: "검색할 문구를 입력해주세요.",
    },

    pager: {
      currentPageInfo: "{0} 중 {1} 페이지",
      totalItemsInfo: "(총 {0} 개)",
      firstPageTooltip: "첫 페이지로 이동",
      lastPageTooltip: "마지막 페이지로 이동",
      nextPageTooltip: "다음 페이지로 이동",
      previousPageTooltip: "이전 페이지로 이동",
      nextPagerTooltip: "다음 페이지로",
      previousPagerTooltip: "이전 페이지로",
    },
  },
});
function ActionItemTable({
  actionItemList,
  refreshData,
}: ActionItemTableProps) {
  const pageOptions = { pageSize: 10 };
  const history = useHistory();
  const user = useUserState();
  const moveToDetail = useCallback(
    (id: number) => {
      history.push({
        pathname: `${AIRoutes.root}/detail/${id}`,
      });
    },
    [history]
  );

  const managerTemplate = useCallback(
    (props: any): any => {
      const primaryManagerList: ManagerInfo[] = props.managers.filter(
        (x: ManagerInfo) => x.isPrimaryManager
      );
      const managerList: ManagerInfo[] = props.managers.filter(
        (x: ManagerInfo) => !x.isPrimaryManager
      );

      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
        >
          <Grid container item xs={12}>
            {primaryManagerList.map((dt, index) => {
              return (
                <Tooltip
                  title={dt.manager.organizationName}
                  key={uuid()}
                  placement="left"
                >
                  <Chip
                    style={{
                      backgroundColor: "#1b4c97",
                      color: "white",
                      margin: "1px 0px",
                    }}
                    label={"정) " + dt.manager.name}
                    size="small"
                  />
                </Tooltip>
              );
            })}
          </Grid>
          <Grid container item xs={12}>
            {managerList.map((dt, index) => {
              return (
                <Tooltip
                  title={dt.manager.organizationName}
                  key={uuid()}
                  placement="left"
                >
                  <Chip
                    style={{
                      backgroundColor: SECONDARY,
                      color: "white",
                      margin: "1px 0px",
                    }}
                    label={"부) " + dt.manager.name}
                    size="small"
                  />
                </Tooltip>
              );
            })}
          </Grid>
        </DataGridContent>
      );
    },
    [moveToDetail]
  );
  const createdAtTemplate = useCallback(
    (props: any): any => {
      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
          style={{ padding: "10px" }}
        >
          {moment(props.createdAt).format("YYYY-MM-DD")}
        </DataGridContent>
      );
    },
    [moveToDetail]
  );
  const organizationTemplate = useCallback(
    (props: any): any => {
      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
          style={{ padding: "10px 0px" }}
        >
          {props?.organizationUnit?.name}
        </DataGridContent>
      );
    },
    [moveToDetail]
  );
  const categoryTemplate = useCallback(
    (props: any): any => {
      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
          style={{ padding: "10px 0px" }}
        >
          {props.categories?.length > 0 &&
            props.categories.map((dt: BasicInfo) => dt.name).join(", ")}
        </DataGridContent>
      );
    },
    [moveToDetail]
  );
  const titleTemplate = useCallback(
    (props: any): any => {
      return (
        <span
          style={{
            fontSize: "14px",
            padding: "10px 0px",
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => {
            moveToDetail(props.id);
          }}
        >
          {props.title}
        </span>
      );
    },
    [moveToDetail]
  );
  const dueDateTemplate = useCallback(
    (props: any): any => {
      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
          style={{ padding: "10px 0px" }}
        >
          {moment(props.dueDate).format("YYYY-MM-DD")}
        </DataGridContent>
      );
    },
    [moveToDetail]
  );

  const statusRef = useRef<any>([]);
  const onChangeStatus = (id: number, e: any, idx: number) => {
    Swal.fire({
      title: `해당 액션아이템의 진행상태를 \n ${e.value}(으)로 변경하시겠습니까?`,
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: SuccessDefault,
      cancelButtonColor: ErrorDefault,
    }).then((result) => {
      if (result.isConfirmed) {
        ActionItemsApi.updateActionItemStatus(id, e.value)
          .then(() => {
            alert("진행상태가 변경되었습니다.");
            refreshData();
          })

          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            statusRef.current[idx].value = null;
          });
      }
    });
  };
  const checkIfManagerMatchesUser = (dt: ActionItemDetailInfo): boolean => {
    return dt.managers.some(
      (managerInfo) => managerInfo.manager.email === user.email
    );
  };
  const statusTemplate = useCallback((props: any): any => {
    let AsmtStatus = props.status;
    let AsmtSubStatus = props.subStatus;
    const hasPermission =
      checkIfManagerMatchesUser(props) ||
      user.roles.includes("ActionItem.admin");
    return (
      <DataGridContent>
        {hasPermission ? (
          <DropDownListComponent
            ref={(scope) => {
              statusRef.current[props.index] = scope;
            }}
            id="ddlelement"
            dataSource={ACTIONITEM_STATUS_ALL}
            placeholder={
              props.subStatus ? `${AsmtStatus}-${AsmtSubStatus}` : AsmtStatus
            }
            onChange={(e: any) => onChangeStatus(props.id, e, props.index)}
          />
        ) : (
          <DataGridContent
            container
            item
            xs={12}
            onClick={() => {
              moveToDetail(props.id);
            }}
            style={{ padding: "10px 0px" }}
          >
            {props.status}
          </DataGridContent>
        )}
      </DataGridContent>
    );
  }, []);

  const calculateCompletionRate = (
    actionItem: ActionItemDetailInfo
  ): number => {
    const children = actionItem.children;

    // children이 ActionItemDetailInfo[] 타입인지 확인
    if (
      !Array.isArray(children) ||
      children.some(
        (child) => typeof child !== "object" || !("status" in child)
      )
    ) {
      console.error("children 배열에 잘못된 데이터가 포함되어 있습니다.");
      return 0; // 잘못된 데이터인 경우 비율 0 반환
    }

    // children이 빈 배열인 경우
    if (children.length === 0) {
      return 0; // 자식이 없는 경우 비율은 0
    }

    // '완료' 상태인 자식들의 수를 계산
    const completedCount = children.filter(
      (child) => child.status === "완료"
    ).length;

    // 완료 비율 계산
    const completionRate = (completedCount / children.length) * 100;

    return completionRate;
  };

  const progressTemplate = useCallback(
    (props: any): any => {
      const completionRate = calculateCompletionRate(props);
      return (
        <DataGridContent
          container
          item
          xs={12}
          onClick={() => {
            moveToDetail(props.id);
          }}
          style={{ padding: "10px 0px" }}
        >
          {props?.children.length > 0 ? (
            <ProgressBarComponent
              id="linear"
              type="Linear"
              width="130px"
              trackThickness={20}
              progressThickness={20}
              showProgressValue={true}
              progressColor={Green500}
              trackColor={Gray100}
              value={completionRate}
              labelStyle={{ color: BaseBlack, size: "14px" }}
              textRender={(args) => {
                args.text =
                  props.children.length > 0
                    ? completionRate.toFixed(2) + " %"
                    : "";
              }}
              animation={{
                enable: false,
              }}
              style={{ marginLeft: "-10px", marginTop: "2px" }}
            />
          ) : (
            ""
          )}
        </DataGridContent>
      );
    },
    [moveToDetail]
  );

  const onRowDataBound = (args: any) => {
    if (!args.data.parentItem) {
      // 최상위 아이템인지 확인
      args.row.classList.add("top-level-item"); // 클래스 추가
    }
  };
  const FilterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  return (
    <Grid container item xs={12} mt="10px">
      <TreeGridComponent
        dataSource={actionItemList}
        treeColumnIndex={0}
        childMapping="children"
        allowFiltering={true}
        allowSorting={true}
        allowPaging={true}
        filterSettings={FilterOptions}
        pageSettings={pageOptions}
        enableCollapseAll={true}
        rowDataBound={onRowDataBound}
        locale="ko"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="title"
            headerText="액션 아이템"
            width="300"
            template={titleTemplate}
          />

          <ColumnDirective
            field="categoriesName"
            headerText="구분"
            width="100"
            template={categoryTemplate}
            allowSorting={false}
          />

          <ColumnDirective
            field="managerNames"
            headerText="담당자"
            width="100"
            template={managerTemplate}
            allowSorting={false}
          />
          <ColumnDirective
            field="dueDate"
            headerText="목표 완료일"
            width="100"
            template={dueDateTemplate}
          />
          <ColumnDirective
            headerText="진행률"
            width="100"
            template={progressTemplate}
            allowSorting={false}
          />
          <ColumnDirective
            field="status"
            headerText="상태"
            width="100"
            template={statusTemplate}
          />
          <ColumnDirective
            field="organizationUnit.name"
            headerText="작성부서"
            width="100"
            template={organizationTemplate}
          />
          <ColumnDirective
            field="createdAt"
            headerText="작성일"
            width="100"
            template={createdAtTemplate}
          />
        </ColumnsDirective>
        <Inject services={[Page, Filter, Sort]} />
      </TreeGridComponent>
    </Grid>
  );
}

export default ActionItemTable;

const DataGridContent = styled(Grid)({
  fontSize: "14px",
  cursor: "pointer",
});

import { Box, Grid, Modal } from "@mui/material";
import { CustomButton, ModalStyle } from "../../styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { CustomText } from "../../styles/CustomText";

import {
  ActionItemInfo,
  OrganizationInfo,
  TriggeringReportInfo,
} from "../../system/types/ActionItem";
import {
  InitActionItemInfo,
  InitTriggeringReport,
} from "../../system/types/initObject";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import * as gregorian from "../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import ActionItemEdit from "./Edit/ActionItemEdit";

export interface ActionEditInfo {
  AIReport: boolean;
  AIInfo: boolean;
}
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
L10n.load({
  ko: {
    daterangepicker: {
      today: "오늘",
      days: "일",
      applyText: "확인",
      cancelText: "취소",
    },
  },
});

function validation(
  data: ActionItemInfo,
  selectedReport: TriggeringReportInfo
) {
  if (
    !data.triggeringReportId &&
    (selectedReport.title === "" || selectedReport.reportDate === "")
  ) {
    AlertModal("check", "시작보고를 선택하거나 작성해 주세요.");
    return false;
  } else if (data.categoryIds.length === 0) {
    AlertModal("check", "카테고리를 선택해 주세요.");
    return false;
  } else if (!data.organizationUnitId) {
    AlertModal("check", "작성부서를 선택해 주세요.");
    return false;
  } else if (data.dueDate === "") {
    AlertModal("check", "목표 완료일을 입력해 주세요.");
    return false;
  } else if (data.priority === "") {
    AlertModal("check", "우선순위를 선택해 주세요.");
    return false;
  } else if (data.title === "") {
    AlertModal("check", "제목을 입력해 주세요.");
    return false;
  } else if (data.managers.length === 0) {
    AlertModal("check", "담당자를 선택해 주세요.");
    return false;
  }
  return true;
}

interface ActionEditProps {
  refreshData: () => void;
  refreshStatusData?: () => void;
  open: boolean;
  handleClose: () => void;
  previousData?: ActionItemInfo;
  type: string;
  organizationunitName?: string | null;
  id?: number;
  teamData: OrganizationInfo[];
}

function ActionEdit({
  refreshData,
  refreshStatusData,
  open,
  handleClose,
  previousData,
  type,
  organizationunitName,
  id,
  teamData,
}: ActionEditProps) {
  const [selectedReport, setSelectedReport] =
    useState<TriggeringReportInfo>(InitTriggeringReport);
  const [data, setData] = useState<ActionItemInfo>(InitActionItemInfo);

  useEffect(() => {
    if (previousData) {
      setData(previousData);
    } else {
      setData(InitActionItemInfo);
      setSelectedReport(InitTriggeringReport);
    }
  }, [previousData, open]);
  var submitcheck = false;

  function onSave(saveType: string) {
    if (submitcheck === false && validation(data, selectedReport)) {
      submitcheck = true;
      if (id) {
        ActionItemsApi.updateActionItem(id, data)
          .then(() => {
            handleClose();
            refreshData();
            if (refreshStatusData) {
              refreshStatusData();
            }
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      } else {
        const saveData =
          saveType === "임시저장" ? { ...data, status: "임시저장" } : data;
        ActionItemsApi.createActionItem(saveData)
          .then(() => {
            handleClose();
            refreshData();
            if (refreshStatusData) {
              refreshStatusData();
            }
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Grid container spacing={2}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            item
            xs={12}
          >
            {type === "생성" ? (
              <CustomText type="title">신규 A/I 등록</CustomText>
            ) : (
              <CustomText type="title">A/I 수정</CustomText>
            )}

            <Grid onClick={handleClose} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </Grid>

            {/* 액션 아이템 내용 */}
            <ActionItemEdit
              data={data}
              setData={setData}
              teamData={teamData}
              organizationunitName={organizationunitName}
              previousState={previousData ? true : false}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="right">
            {type === "생성" && !previousData && (
              <CustomButton
                iconCss="e-icons e-save"
                onClick={() => onSave("임시저장")}
                style={{ marginRight: "5px" }}
              >
                {"임시저장"}
              </CustomButton>
            )}
            <CustomButton
              iconCss="e-icons e-save"
              onClick={() => onSave("저장")}
            >
              {"저장"}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ActionEdit;

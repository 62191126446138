import { Grid } from "@mui/material";
import { useState } from "react";
import AIMeetingView from "./AIMeetingView";
import AIMeetingTable from "./AIMeetingTable";
import AIMeetingEdit from "./AIMeetingEdit";
import { ActionItemDetailInfo } from "../../../system/types/ActionItem";

interface AIMeetingNoteProps {
  data: ActionItemDetailInfo | null;
}

function AIMeetingNote({ data }: AIMeetingNoteProps) {
  // table, detail, edit
  const [type, setType] = useState("table");
  const [noteId, setNoteId] = useState<number | null>(null);

  return (
    <>
      {data && (
        <Grid item xs={12}>
          {type === "table" && (
            <AIMeetingTable
              id={data.id}
              articleType="미팅노트"
              setNoteId={setNoteId}
              setType={setType}
            />
          )}
          {type === "detail" && (
            <AIMeetingView aiData={data} noteId={noteId} setType={setType} />
          )}
          {type === "edit" && (
            <AIMeetingEdit id={data.id} meetingId={noteId} setType={setType} />
          )}
        </Grid>
      )}
    </>
  );
}

export default AIMeetingNote;

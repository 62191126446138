import { Autocomplete, Box, Chip, styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SECONDARY } from "../../../styles/theme";
import UserApi from "../../../system/api/UserApi";
import { ErrorHandler } from "../../../system/ApiService";
import { ManagerInfo } from "../../../system/types/ActionItem";
import { WorkUser } from "../../../system/types/type";
import AlertModal from "../../Common/AlertModal";

interface SelectManagerProps {
  list?: ManagerInfo[];
  onChange: (data: ManagerInfo[]) => void;
  onClickChip: (id: number) => void;
}

function SelectManager({
  list,
  onChange: _onChange,
  onClickChip: _onClickChip,
}: SelectManagerProps) {
  const [data, setData] = useState<ManagerInfo[]>([]);

  useEffect(() => {
    UserApi.GetAIUsers("")
      .then((res) => {
        const transformedData = res.data.map((user: WorkUser) => ({
          isPrimaryManager: false,
          manager: {
            id: user.id,
            name: user.name,
            jobTitle: user.jobTitle,
            jobPosition: user.jobPosition,
            isResignation: user.resignation,
            organizationName: user.team
              ? user.team
              : user.department
              ? user.department
              : user.group,
            email: user.email,
          },
        }));

        setData(transformedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const boxSpan = {
    marginLeft: "20px",
    fontSize: "14px",
    color: "#888888",
  };
  const auto = { width: "100%", minWidth: "200px" };

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={data}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        disableClearable
        onChange={(event: any, newValue: ManagerInfo[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(option: ManagerInfo, value: ManagerInfo) =>
          option.manager.id === value.manager.id
        }
        getOptionLabel={(option: ManagerInfo) => option.manager.name}
        renderOption={(props, option: ManagerInfo) => (
          <Box component="li" {...props}>
            {option.manager.name}
            <span
              style={boxSpan}
            >{`\t ${option.manager.jobTitle}, ${option.manager.jobPosition}`}</span>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder={list && list.length > 0 ? "" : "검색 후 선택해주세요."}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              style={{
                backgroundColor: option.isPrimaryManager
                  ? "#1b4c97"
                  : SECONDARY,
                color: "white",
              }}
              onClick={() => _onClickChip(option.manager.id)}
              label={
                option.isPrimaryManager
                  ? "정) " +
                    option.manager.organizationName +
                    " " +
                    option.manager.name
                  : "부) " +
                    option.manager.organizationName +
                    " " +
                    option.manager.name
              }
              {...getTagProps({ index })}
            />
          ))
        }
        style={auto}
      />
    </>
  );
}

export default SelectManager;

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    padding: "3px 5px !important",
    color: "#000000",
    "&.Mui-focused fieldset": {
      borderColor: "#e3165b",
    },
  },
});

import { Chip, Grid, IconButton, styled, Tooltip } from "@mui/material";
import {
  AILastContentBox,
  AITableCardBox,
  AITableContentsBox,
  AITableTitlBox,
} from "../../../styles/theme";
import { AttachFile } from "../../../system/types/type";
import { CustomText } from "../../../styles/CustomText";
import {
  ErrorDefault,
  Gray700,
  SuccessDefault,
  WaitingDefault,
} from "../../../styles/Colors";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import AlertModal from "../../Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import FileApi from "../../../system/api/fileApi";
import { useRef } from "react";

interface AIDetailAttachmentProps {
  data: AttachFile[] | undefined;
  id: number;
  refreshData: () => void;
  hasPermission: boolean;
}

function AIDetailAttachment({
  data,
  id,
  refreshData,
  hasPermission,
}: AIDetailAttachmentProps) {
  const Loading = useLoadingDispatch();
  const deleteAttachment = (attachmentId: number | null) => {
    if (id && attachmentId) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 제품이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: SuccessDefault,
        cancelButtonColor: ErrorDefault,
      }).then((result) => {
        if (result.isConfirmed) {
          ActionItemsApi.deleteActionItemAttachment(id, attachmentId)
            .then(() => {
              AlertModal("success", "선택하신 첨부파일이 삭제되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => AlertModal("msg", "파일다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleChipClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  var submitcheck = false;
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (submitcheck === false && event.target.files && id) {
      // 파일이 선택된 경우
      const file = event.target.files[0]; // 첫 번째 파일만 가져옴
      const maxSize = 1024 * 1024 * 1024; // 1GB 제한
      if (file.size > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      submitcheck = true;
      let promise: Promise<any>;
      Loading({ type: "LOADING" });
      // 파일 정보 저장
      const formData = new FormData();
      Array.from(event.target.files).forEach((el) => {
        formData.append("uploadFiles", el);
      });
      FileApi.upload(formData)
        .then((file) => {
          promise = ActionItemsApi.createActionItemAttachment(id, file[0]);
          promise
            .then((res) => {
              AlertModal("success", "첨부파일이 등록되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  return (
    <AITableCardBox mt="10px">
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
      >
        <CustomText type="subtitle" bold600 color={Gray700}>
          첨부 파일
        </CustomText>
        {hasPermission && (
          <Grid item mt="-5px">
            <Chip
              variant="outlined"
              color="success"
              size="small"
              label="추가"
              icon={<AddIcon />}
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={handleChipClick}
            />
            <DisabledImgBox
              id={"file"}
              type="file"
              ref={fileInputRef}
              onChange={onSaveFile}
            ></DisabledImgBox>
          </Grid>
        )}
      </Grid>
      <AITableContentsBox container item xs={12}>
        {data && data.length > 0 ? (
          <Grid container item xs={12}>
            <AITableTitlBox container item xs={12} alignItems="center">
              <Grid container justifyContent="center" item xs={10}>
                파일 이름
              </Grid>
              <Grid container justifyContent="center" item xs={2}></Grid>
            </AITableTitlBox>
            {data.map((child: AttachFile, index: number) => (
              <AILastContentBox
                container
                key={index}
                padding="5px 0px 5px 5px"
                alignItems="center"
              >
                <Grid container item xs={10}>
                  <CustomText type="body" noWrap>
                    {child.filename}
                  </CustomText>
                </Grid>
                <Grid container justifyContent="right" item xs={2}>
                  <Tooltip title="다운로드">
                    <DownLoadIconButton
                      onClick={() =>
                        downloadFile(child.filename, child.filePath)
                      }
                    >
                      <DownloadIcon fontSize="small" />
                    </DownLoadIconButton>
                  </Tooltip>
                  <Tooltip title="삭제">
                    <DeleteIconButton
                      onClick={() => {
                        deleteAttachment(child?.id ? child.id : null);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </DeleteIconButton>
                  </Tooltip>
                </Grid>
              </AILastContentBox>
            ))}
          </Grid>
        ) : (
          <Grid container item xs={12} justifyContent="center" margin="20px">
            등록된 첨부파일이 없습니다.
          </Grid>
        )}
      </AITableContentsBox>
    </AITableCardBox>
  );
}

export default AIDetailAttachment;

const DownLoadIconButton = styled(IconButton)({
  "&:hover": {
    color: WaitingDefault,
  },
});

const DeleteIconButton = styled(IconButton)({
  "&:hover": {
    color: ErrorDefault,
  },
});

const DisabledImgBox = styled("input")(() => ({
  width: "0",
  height: "0",
  padding: "0",
  overflow: "hidden",
  border: "0",
}));

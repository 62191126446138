import { Autocomplete, Box, Chip } from "@mui/material";
import { useMemo, useState } from "react";
import { CustomTextField, SECONDARY } from "../../styles/theme";
import UserApi from "../../system/api/UserApi";
import { ErrorHandler } from "../../system/ApiService";
import { WorkUser } from "../../system/types/type";

interface SelectMultiEmployeeProps {
  list?: WorkUser[];
  onChange: (data: WorkUser[]) => void;
}

function SelectMultiEmployee({
  list,
  onChange: _onChange,
}: SelectMultiEmployeeProps) {
  const [data, setData] = useState<WorkUser[]>([]);

  useMemo(() => {
    UserApi.GetAIUsers("")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      });
  }, []);

  const boxSpan = {
    marginLeft: "20px",
    fontSize: "14px",
    color: "#888888",
  };
  const chip = { backgroundColor: SECONDARY, color: "white" };
  const auto = { width: "100%", minWidth: "500px" };

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={data}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        onChange={(event: any, newValue: WorkUser[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(option: WorkUser, value: WorkUser) =>
          option.id === value.id
        }
        getOptionLabel={(option: WorkUser) => option.name}
        renderOption={(props, option: WorkUser) => (
          <Box component="li" {...props}>
            {option.name}
            <span style={boxSpan}>{`\t ${
              option.team
                ? option.team
                : option.department
                ? option.department
                : option.group
            }, ${option.jobPosition}`}</span>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder="이름으로 검색 후 선택해 주세요."
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              style={chip}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        style={auto}
      />
    </>
  );
}

export default SelectMultiEmployee;

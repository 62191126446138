import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, Chip, Grid, IconButton } from "@mui/material";
import {
  ButtonComponent,
  RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CustomButton, ReportLayout, SECONDARY } from "../../../styles/theme";
import { ErrorHandler } from "../../../system/ApiService";
import ReportNoteApi from "../../../system/api/ReportApi";
import UserApi from "../../../system/api/UserApi";
import FileApi from "../../../system/api/fileApi";
import { useUserState } from "../../../system/context/UserContext";
import { ReportDetail } from "../../../system/types/Report";
import { InitReportDetail } from "../../../system/types/initObject";
import {
  IlocationIntern,
  ReportRoutes,
  WorkUser,
} from "../../../system/types/type";
import CrossEditor from "../../Common/Editor/CrossEditor";
import { defaultReportValue } from "../../Common/Editor/defaultReportValue";
import SelectMultiEmployee from "../../Common/SelectMultiEmployee";
import SubTitleDiv from "../../Common/SubTitleDiv";
import TitleDiv from "../../Common/TitleDiv";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";

interface Params {
  id: string;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function ReportEdit() {
  const { id } = useParams<Params>();
  const reportId = parseInt(id);
  const history = useHistory();
  const location = useLocation<IlocationIntern>();
  const parent = location.state;
  const user = useUserState();
  const refContent = createRef<CrossEditor>();
  const [fileList, setFileList] = React.useState<File[]>([]);
  const [data, setData] = React.useState<ReportDetail>(InitReportDetail);
  const [nameList, setNameList] = useState<string[]>([]);
  // const [graphdata, setGraphData]: any = useState([]);
  const [ceoReportEndTime, setCeoReportEndTime] = useState("");
  const [started, setStarted] = useState<boolean>(false);
  const [reportComplete, setReportComplete] = useState<boolean>(false);
  const loadingDispatch = useLoadingDispatch();
  let datePick: any;
  const params = {
    Width: "100%",
    Height: 600,
  };
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  useEffect(() => {
    if (reportId) {
      ReportNoteApi.getReportDetail(reportId)
        .then((res) => {
          if (res.data.type === "중간보고") {
            setData({ ...res.data, midRepo: true, finRepo: false });
          } else {
            setData({ ...res.data, finRepo: true, midRepo: false });
          }
          setCeoReportEndTime(res.data.endReportTime);
          setReportComplete(res.data.ceoReportStatus);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      UserApi.GetAIUsers(user.name)
        .then((res) => {
          if (parent.workType === "인턴" && parent?.participant) {
            setData({
              ...data,
              participants: parent.participant.filter((x) => !x.mentor),
            });
          } else {
            setData({ ...data, participants: res.data });
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, user.name]);

  const saveComplete = () => {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    // validation
    let flag = true;
    if (parent.workType === "인턴") {
      if (data.title === "" || data.type === "") {
        alert("모든 입력값을 채워주세요.");
        flag = false;
        return;
      }
    } else {
      if (data.participants.length < 1) {
        alert("1명 이상의 참여자가 지정되어야 합니다.");
        flag = false;
        return;
      } else if (data.title === "" || data.type === "") {
        alert("모든 입력값을 채워주세요.");
        flag = false;
        return;
      } else if (
        reportComplete === false &&
        (data.startReportTime.split(" ")[1] === "00:00:00" ||
          ceoReportEndTime === "")
      ) {
        alert("모든 입력값을 채워주세요.");
        flag = false;
        return;
      }
    }

    // 최종 저장
    if (flag && refContent.current) {
      const editor = refContent.current.editorRef;
      let promise: Promise<any>;
      loadingDispatch({ type: "LOADING" });
      if (fileList.length > 0) {
        const formData = new FormData();
        Array.from(fileList).forEach((el) => {
          formData.append("uploadFiles", el);
        });
        FileApi.upload(formData)
          .then((file) => {
            if (data.id === 0) {
              promise = ReportNoteApi.createReportList({
                ...data,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
                parentId: parent.parentId,
                endReportTime: ceoReportEndTime,
                ceoReportStatus: reportComplete,
              });
            } else {
              promise = ReportNoteApi.updateReport({
                ...data,
                attachmentFiles: data.attachmentFiles.concat(file),
                content: editor.GetBodyValue() || "",
                endReportTime: ceoReportEndTime,
              });
            }
            promise
              .then((res) => {
                setData(res.data);
                alert("정상적으로 등록되었습니다.");
                history.push({
                  pathname: `${ReportRoutes.root}/detail/${res.data.id}`,
                  state: parent,
                });
              })
              .catch((err) => {
                let msg = ErrorHandler(err);
                alert(msg);
              });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      } else {
        if (data.id === 0) {
          promise = ReportNoteApi.createReportList({
            ...data,
            content: editor.GetBodyValue() || "",
            parentId: parent.parentId,
            endReportTime: ceoReportEndTime,
            ceoReportStatus: reportComplete,
          });
        } else {
          promise = ReportNoteApi.updateReport({
            ...data,
            content: editor.GetBodyValue() || "",
            endReportTime: ceoReportEndTime,
          });
        }
        promise
          .then((res) => {
            setData(res.data);
            alert("정상적으로 등록되었습니다.");
            history.push({
              pathname: `${ReportRoutes.root}/detail/${res.data.id}`,
              state: parent,
            });
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            loadingDispatch({ type: "COMPLETE" });
          });
      }
    }
  };

  const onChangeDate = (args: any) => {
    if (args.target.value) {
      var pickDate = args.target.value;
      let month = pickDate.getMonth() + 1;
      let day = pickDate.getDate();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;

      var date =
        pickDate.getFullYear() + "-" + month + "-" + day + " " + "00:00:00";
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: date,
      });
    }
  };

  const onChange = (args: any) => {
    if (args.target.name === "startReportTime" && args.target.value) {
      var pickDate = args.target.value;
      let month = pickDate.getMonth() + 1;
      let day = pickDate.getDate();
      let hour = pickDate.getHours();
      let minute = pickDate.getMinutes();
      let second = pickDate.getSeconds();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour >= 10 ? hour : "0" + hour;
      minute = minute >= 10 ? minute : "0" + minute;
      second = second >= 10 ? second : "0" + second;

      var date =
        pickDate.getFullYear() +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: date,
      });
    } else if (args.target.name === "ceoReportEndTime" && args.target.value) {
      var startDate = new Date(data.startReportTime);

      let month = startDate.getMonth() + 1;
      let day = startDate.getDate();

      var pickDate = args.target.value;
      let hour = pickDate.getHours();
      let minute = pickDate.getMinutes();
      let second = pickDate.getSeconds();

      let pickMonth = month >= 10 ? month : "0" + month;
      let pickDay = day >= 10 ? day : "0" + day;

      let pickHour = hour >= 10 ? hour : "0" + hour;
      let pickMinute = minute >= 10 ? minute : "0" + minute;
      let pickSecond = second >= 10 ? second : "0" + second;

      var date =
        startDate.getFullYear() +
        "-" +
        pickMonth +
        "-" +
        pickDay +
        " " +
        pickHour +
        ":" +
        pickMinute +
        ":" +
        pickSecond;
      setCeoReportEndTime(date);
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  const onChangeRepo = (args: any) => {
    if (
      window.confirm(
        "보고 종류를 변경할 경우 기존 작성 내용은 삭제됩니다. 그래도 변경하시겠습니까?"
      )
    ) {
      if (args.target.value === "최종보고") {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: args.target.value,
          content: defaultReportValue,
          midRepo: false,
          finRepo: true,
        });
      } else {
        setData({
          ...data,
          [(args.target as HTMLInputElement).name]: args.target.value,
          content: "",
          midRepo: true,
          finRepo: false,
        });
      }
    }
  };
  const onChangeCheckbox = (args: any) => {
    setReportComplete(!reportComplete);
    setData({ ...data, startReportTime: "" });
  };

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      // 미리보기 기능
      const targetFiles = Array.from(event.target.files);
      const totalSize = targetFiles.reduce((acc, file) => acc + file.size, 0);
      const maxSize = 1024 * 1024 * 1024;
      if (totalSize > maxSize) {
        alert("업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      setFileList(targetFiles);
      const selectedFile: string[] = [];
      targetFiles.map((file) => {
        return selectedFile.push(file.name);
      });
      setNameList(selectedFile);
    }
  };

  const onChangeEmp = (userList: WorkUser[]) => {
    if (userList) {
      setData({ ...data, participants: userList });
    }
  };

  const deleteFile = (e: any) => {
    const fileArr = nameList.filter((x, idx) => idx !== e);
    const uploadArr = fileList.filter((x, idx) => idx !== e);
    setNameList([...fileArr]);
    setFileList([...uploadArr]);
  };

  const maxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  const minTime = new Date("1/1/2024 9:00 AM");
  const maxTime = new Date("1/1/2024 7:00 PM");
  const minStartTime = new Date(data.startReportTime);

  // function disabledDate(args: { text: string; isDisabled: boolean }) {
  //   if (graphdata) {
  //     for (var i = 0; i < graphdata.length; i++) {
  //       if (graphdata[i][0] === "0") {
  //         var checkTime = graphdata[i].slice(1);
  //         if (args.text === checkTime) {
  //           args.isDisabled = true;
  //         }
  //       } else {
  //         if (args.text === graphdata[i]) {
  //           args.isDisabled = true;
  //         }
  //       }
  //     }
  //   }
  // }

  const deleteExistFile = (idx: number) => {
    if (
      window.confirm(
        "파일을 정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다."
      )
    ) {
      setData({
        ...data,
        attachmentFiles: data.attachmentFiles.filter((x, i) => i !== idx),
      });
    }
  };

  // css
  const chip = {
    color: "white",
    margin: "2px",
  };

  const checkBox = {
    display: "flex",
    justifyContent: "space-evenly",
  };

  const participantBox = {
    padding: "15px",
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="보고서 등록"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <SubTitleDiv title={parent.parentTitle}></SubTitleDiv>
      </Grid>
      <Grid item xs={12}>
        <div>보고서명</div>
      </Grid>
      <Grid item xs={12}>
        <TextBoxComponent
          placeholder="보고서명을 입력해 주세요."
          style={{ height: "48px" }}
          cssClass="e-outline"
          value={data.title}
          type="text"
          name="title"
          onChange={onChange}
        />
        {data.title.length === 0 ? (
          <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
            <span className="e-icons e-circle-check"></span> 보고서명이 입력되어
            있지 않습니다.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        <div>참여자</div>
      </Grid>
      {parent.workType === "인턴" ? (
        <div style={participantBox}>
          {data.participants.map((user, idx) => {
            return (
              <Chip
                key={idx}
                style={{
                  ...chip,
                  backgroundColor: user.mentor ? "#1b4c97" : SECONDARY,
                }}
                label={user.mentor ? "MT " + user.name : user.name}
              />
            );
          })}
        </div>
      ) : (
        <Grid item xs={12}>
          <SelectMultiEmployee
            list={data.participants}
            onChange={onChangeEmp}
          ></SelectMultiEmployee>
          {data.participants.length < 1 ? (
            <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
              <span className="e-icons e-circle-check"></span> 참여자가 지정되어
              있지 않습니다.
            </div>
          ) : (
            ""
          )}
        </Grid>
      )}

      {parent.workType === "인턴" ? (
        ""
      ) : (
        <>
          <Grid item xs={4}>
            <div>
              보고일정{" "}
              <Checkbox
                {...label}
                color="default"
                checked={reportComplete}
                onClick={onChangeCheckbox}
                name="ceoReportStatus"
              />
              보고완료
            </div>
            {reportComplete === true ? (
              <>
                <DatePickerComponent
                  name="startReportTime"
                  value={new Date(data.startReportTime)}
                  format="yyyy-MM-dd "
                  onChange={onChangeDate}
                  max={maxDate}
                  placeholder="날짜를 선택해 주세요."
                  cssClass="e-outline e-custom"
                  style={{ height: "48px" }}
                  focus={(e) => datePick.show()}
                  ref={(scope) => {
                    datePick = scope;
                  }}
                  showClearButton={false}
                ></DatePickerComponent>
              </>
            ) : (
              <DatePickerComponent
                name="startReportTime"
                value={new Date(data.startReportTime)}
                format="yyyy-MM-dd "
                onChange={onChangeDate}
                placeholder="날짜를 선택해 주세요."
                cssClass="e-outline e-custom"
                style={{ height: "48px" }}
                focus={(e) => datePick.show()}
                ref={(scope) => {
                  datePick = scope;
                }}
                showClearButton={false}
              ></DatePickerComponent>
            )}

            {data.startReportTime.length === 0 ? (
              <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                <span className="e-icons e-circle-check"></span> 보고일정이
                입력되어 있지 않습니다.
              </div>
            ) : (
              ""
            )}
          </Grid>
          <></>
          {reportComplete ? null : (
            <>
              {data.startReportTime === "" ? (
                ""
              ) : (
                <Grid item xs={4}>
                  <ReportLayout>시작 시간</ReportLayout>
                  <TimePickerComponent
                    name="startReportTime"
                    id="time"
                    onChange={onChange}
                    value={new Date(data.startReportTime)}
                    // itemRender={disabledDate}
                    placeholder="시간을 선택해 주세요."
                    style={{ height: "40px" }}
                    min={minTime}
                    max={maxTime}
                    showClearButton={false}
                  />
                  {data.startReportTime.split(" ")[1] === "00:00:00" ? (
                    <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                      <span className="e-icons e-circle-check"></span> 시작
                      시간이 입력되어 있지 않습니다.
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {data.startReportTime === "" ||
              data.startReportTime.split(" ")[1] === "00:00:00" ? (
                ""
              ) : (
                <Grid item xs={4}>
                  <ReportLayout>종료 시간</ReportLayout>
                  <TimePickerComponent
                    name="ceoReportEndTime"
                    id="time"
                    onChange={onChange}
                    value={new Date(data.endReportTime)}
                    // itemRender={disabledDate}
                    placeholder="시간을 선택해 주세요."
                    style={{ height: "40px" }}
                    min={minStartTime}
                    max={maxTime}
                    showClearButton={false}
                  />
                  {ceoReportEndTime === "" ? (
                    <div style={{ color: "#CC1F3B", fontSize: "0.8rem" }}>
                      <span className="e-icons e-circle-check"></span> 종료
                      시간이 입력되어 있지 않습니다.
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </>
          )}
        </>
      )}

      <Grid item xs={12}>
        <div>종류</div>
      </Grid>
      <Grid item xs={12}>
        <form style={checkBox}>
          <RadioButtonComponent
            checked={data.type === "중간보고"}
            name="type"
            value="중간보고"
            label="중간보고"
            onChange={onChangeRepo}
          />
          <RadioButtonComponent
            checked={data.type === "최종보고"}
            name="type"
            value="최종보고"
            label="최종보고"
            onChange={onChangeRepo}
          />
        </form>
        {data.type === "" ? (
          <div
            style={{ color: "#CC1F3B", fontSize: "0.8rem", marginTop: "5px" }}
          >
            <span className="e-icons e-circle-check"></span> 종류를
            선택해주세요.
          </div>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        {data.midRepo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
        {data.finRepo && (
          <CrossEditor
            ref={refContent}
            name="InBodyAssignmentWork"
            params={params}
            value={data.content}
            onLoaded={onLoaded}
          />
        )}
      </Grid>
      {data.id !== 0 && (
        <Grid item xs={12} style={{ margin: "10px 0px" }}>
          기존파일 :{" "}
          {data.attachmentFiles &&
            data.attachmentFiles.map((x, idx) => (
              <span key={idx} style={{ fontSize: "14px", margin: "3px" }}>
                {x.filename}
                <ButtonComponent
                  style={{ marginLeft: "3px", fontSize: "14px" }}
                  cssClass="e-small e-danger"
                  onClick={() => deleteExistFile(idx)}
                >
                  삭제
                </ButtonComponent>
              </span>
            ))}
        </Grid>
      )}
      <Grid item xs={12}>
        <input id="file" type="file" onChange={onSaveFile} multiple></input>
        <br />
        <div>
          {nameList &&
            nameList.map((e, idx) => (
              <div key={idx} style={{ fontSize: "14px" }}>
                {e}
                <IconButton
                  size="small"
                  style={{ padding: "0px" }}
                  onClick={() => deleteFile(idx)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <CustomButton iconCss="e-icons e-check" onClick={saveComplete}>
          저장
        </CustomButton>
      </Grid>
    </Grid>
  );
}

export default ReportEdit;

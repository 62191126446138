import { Chip, Grid, IconButton, styled, Tooltip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AIBoxContent,
  AIBoxTitle,
  AILastContentBox,
  AITableCardBox,
  AITableContentsBox,
  AITableTitlBox,
  CustomButton,
  PRIMARY,
  SECONDARY,
  TableFixedHeight,
} from "../../../styles/theme";

import { ErrorHandler } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import HtmlContent from "../../Common/HtmlContent";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { ActionItemsApi } from "../../../system/api/ActionItemsApi";
import {
  ActionItemArticleDetailInfo,
  ActionItemDetailInfo,
  BasicInfo,
} from "../../../system/types/ActionItem";
import AlertModal from "../../Common/AlertModal";
import Swal from "sweetalert2";
import {
  ErrorDefault,
  Gray300,
  Gray400,
  Gray700,
  SuccessDefault,
  WaitingDefault,
} from "../../../styles/Colors";
import { CustomText } from "../../../styles/CustomText";
import AddIcon from "@mui/icons-material/Add";
import FileApi from "../../../system/api/fileApi";
import { AttachFile } from "../../../system/types/type";
interface AIMeetingViewProps {
  aiData: ActionItemDetailInfo;
  noteId: number | null;
  setType: (type: string) => void;
}

function AIMeetingView({ aiData, noteId, setType }: AIMeetingViewProps) {
  const user = useUserState();
  const [data, setData] = useState<ActionItemArticleDetailInfo | null>(null);

  const refreshData = useCallback(() => {
    if (noteId && aiData.id) {
      ActionItemsApi.getActionItemNoteDetail(aiData.id, "미팅노트", noteId)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [noteId, aiData.id]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const onUpdatePage = () => {
    setType("edit");
  };
  const Loading = useLoadingDispatch();
  const downloadFile = (filename: string, filepath: string) => {
    Loading({ type: "LOADING" });
    FileApi.FileDownload(filename, filepath)
      .then((res) => {
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => AlertModal("msg", "파일 다운로드 중에 오류가 발생했습니다."))
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  const onDeletePage = () => {
    if (aiData.id && noteId) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 미팅노트가 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: SuccessDefault,
        cancelButtonColor: ErrorDefault,
      }).then((result) => {
        if (result.isConfirmed && aiData.id && noteId) {
          ActionItemsApi.deleteActionItemNote(aiData.id, "미팅노트", noteId)
            .then(() => {
              AlertModal("success", "선택하신 미팅노트가 삭제되었습니다.");
              setType("table");
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  const backAI = () => {
    setType("table");
  };

  const onPrintHandler = () => {
    const html = document.querySelector("html");
    const printContents = document.getElementById("download_area")?.innerHTML;
    const printDiv = document.createElement("DIV");

    if (html && printContents) {
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    }
  };

  const chip = { backgroundColor: SECONDARY, color: "white" };
  const leftBtn = { marginLeft: "10px" };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleChipClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  var submitcheck = false;
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (submitcheck === false && event.target.files && aiData.id && noteId) {
      // 파일이 선택된 경우
      const file = event.target.files[0]; // 첫 번째 파일만 가져옴
      const maxSize = 1024 * 1024 * 1024; // 1GB 제한
      if (file.size > maxSize) {
        AlertModal("msg", "업로드 파일은 1GB를 넘을 수 없습니다.");
        return;
      }
      submitcheck = true;
      let promise: Promise<any>;
      Loading({ type: "LOADING" });
      // 파일 정보 저장
      const formData = new FormData();
      Array.from(event.target.files).forEach((el) => {
        formData.append("uploadFiles", el);
      });
      FileApi.upload(formData)
        .then((file) => {
          promise = ActionItemsApi.createActionItemNoteAttachment(
            aiData.id,
            "미팅노트",
            noteId,
            file[0]
          );
          promise
            .then((res) => {
              AlertModal("success", "첨부파일이 등록되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
          submitcheck = false;
        })
        .finally(() => Loading({ type: "COMPLETE" }));
    }
  };

  const deleteAttachment = (attachmentId: number | null) => {
    if (aiData.id && noteId && attachmentId) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 제품이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: SuccessDefault,
        cancelButtonColor: ErrorDefault,
      }).then((result) => {
        if (result.isConfirmed) {
          ActionItemsApi.deleteActionItemNoteAttachment(
            aiData.id,
            "미팅노트",
            noteId,
            attachmentId
          )
            .then(() => {
              AlertModal("success", "선택하신 첨부파일이 삭제되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  if (data) {
    return (
      <>
        {data && (
          <Grid container justifyContent="center">
            <Grid item xs={2}>
              <CustomButton onClick={backAI} iconCss="e-icons e-chevron-left">
                미팅노트 목록
              </CustomButton>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              {data.participants.findIndex((x) => x.name === user.name) !==
              -1 ? (
                <>
                  <CustomButton
                    style={{ color: PRIMARY }}
                    iconCss="e-icons e-delete-1"
                    onClick={onDeletePage}
                  >
                    삭제
                  </CustomButton>
                  <CustomButton
                    style={leftBtn}
                    iconCss="e-icons e-edit"
                    onClick={onUpdatePage}
                  >
                    수정
                  </CustomButton>
                </>
              ) : (
                <></>
              )}
              <CustomButton
                style={leftBtn}
                iconCss="e-icons e-download"
                onClick={onPrintHandler}
              >
                다운로드
              </CustomButton>
            </Grid>
            <Grid container item xs={12} id="download_area">
              <AITableCardBox mt="10px">
                <Grid container item xs={12} alignItems="center" padding="10px">
                  <CustomText type="subtitle" bold600 color={Gray700}>
                    A/I 내용
                  </CustomText>
                </Grid>

                <AITableContentsBox container item xs={12}>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        A/I발생 회의명
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {aiData.triggeringReport.title}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        제목
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {aiData.title}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        카테고리
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {aiData.categories?.length > 0 &&
                          aiData.categories
                            .map((dt: BasicInfo) => dt.name)
                            .join(", ")}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                </AITableContentsBox>
              </AITableCardBox>
              <AITableCardBox mt="10px">
                <Grid container item xs={12} alignItems="center" padding="10px">
                  <CustomText type="subtitle" bold600 color={Gray700}>
                    미팅노트 내용
                  </CustomText>
                </Grid>
                <AITableContentsBox container item xs={12}>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        제목
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {data.title}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        참여자
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {data.participants.map((user, idx) => {
                          return (
                            <Chip key={idx} style={chip} label={user.name} />
                          );
                        })}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        미팅일자
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={10} pl="10px">
                      <CustomText type="subtitle" bold400 color={Gray700}>
                        {data.meetingDate
                          ? new Date(data.meetingDate).toLocaleDateString()
                          : "-"}
                      </CustomText>
                    </AIBoxContent>
                  </TableFixedHeight>
                  <TableFixedHeight container item xs={12} alignItems="center">
                    <AIBoxTitle
                      container
                      item
                      xs={2}
                      pr="10px"
                      justifyContent="right"
                      alignItems="center"
                    >
                      <CustomText
                        type="subtitle"
                        bold400
                        lineSpace
                        color={Gray400}
                      >
                        내용
                      </CustomText>
                    </AIBoxTitle>
                    <AIBoxContent item xs={9} pl="10px">
                      <HtmlContent
                        style={{
                          minHeight: "300px",
                          marginBottom: "5px",
                          border: `1px solid ${Gray300}`,
                          borderRadius: "8px",
                          padding: "5px",
                          color: Gray700,
                        }}
                        data={
                          data.content
                            ? data.content
                            : "작성된 내용이 없습니다."
                        }
                      ></HtmlContent>
                    </AIBoxContent>
                  </TableFixedHeight>
                </AITableContentsBox>
              </AITableCardBox>
              <AITableCardBox mt="10px">
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  padding="10px"
                >
                  <CustomText type="subtitle" bold600 color={Gray700}>
                    첨부 파일
                  </CustomText>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      color="success"
                      size="small"
                      label="추가"
                      icon={<AddIcon />}
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={handleChipClick}
                    />
                    <DisabledImgBox
                      id={"file"}
                      type="file"
                      ref={fileInputRef}
                      onChange={onSaveFile}
                    ></DisabledImgBox>
                  </Grid>
                </Grid>
                <AITableContentsBox container item xs={12}>
                  {data?.attachmentFiles && data.attachmentFiles.length > 0 ? (
                    <Grid container item xs={12}>
                      <AITableTitlBox
                        container
                        item
                        xs={12}
                        alignItems="center"
                      >
                        <Grid container justifyContent="center" item xs={10}>
                          파일 이름
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          item
                          xs={2}
                        ></Grid>
                      </AITableTitlBox>
                      {data.attachmentFiles.map(
                        (child: AttachFile, index: number) => (
                          <AILastContentBox
                            container
                            key={index}
                            padding="5px 0px 5px 5px"
                            alignItems="center"
                          >
                            <Grid container item xs={10}>
                              <CustomText type="body" noWrap>
                                {child.filename}
                              </CustomText>
                            </Grid>
                            <Grid container justifyContent="right" item xs={2}>
                              <Tooltip title="다운로드">
                                <DownLoadIconButton
                                  onClick={() =>
                                    downloadFile(child.filename, child.filePath)
                                  }
                                >
                                  <DownloadIcon fontSize="small" />
                                </DownLoadIconButton>
                              </Tooltip>
                              <Tooltip title="삭제">
                                <DeleteIconButton
                                  onClick={() => {
                                    deleteAttachment(
                                      child?.id ? child.id : null
                                    );
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </DeleteIconButton>
                              </Tooltip>
                            </Grid>
                          </AILastContentBox>
                        )
                      )}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      margin="20px"
                    >
                      등록된 첨부파일이 없습니다.
                    </Grid>
                  )}
                </AITableContentsBox>
              </AITableCardBox>
            </Grid>
          </Grid>
        )}
      </>
    );
  } else {
    return <>데이터를 조회할 수 없습니다.</>;
  }
}

export default AIMeetingView;
const DownLoadIconButton = styled(IconButton)({
  "&:hover": {
    color: WaitingDefault,
  },
});

const DeleteIconButton = styled(IconButton)({
  "&:hover": {
    color: ErrorDefault,
  },
});
const DisabledImgBox = styled("input")(() => ({
  width: "0",
  height: "0",
  padding: "0",
  overflow: "hidden",
  border: "0",
}));

import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { ActionItemDetailInfo } from "../../system/types/ActionItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Chip,
  emphasize,
  Grid,
  styled,
  Tab,
  Tabs,
} from "@mui/material";

import { Brand600, Gray900 } from "../../styles/Colors";
import ActionItemDetails from "./ActionItemDetails";
import AIMeetingNote from "./AIMeeting/AIMeetingNote";
import AIReport from "./AIReport/AIReport";
import HomeIcon from "@mui/icons-material/Home";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { grey } from "@mui/material/colors";
import { AIRoutes } from "../../system/types/type";
interface Params {
  id: string;
}
function ActionItemTabs() {
  const { id } = useParams<Params>();
  const actionItemId = parseInt(id);
  const [data, setData] = useState<ActionItemDetailInfo | null>(null);
  const [value, setValue] = useState(1);

  const refreshData = useCallback(() => {
    ActionItemsApi.getActionItemsDetail(actionItemId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [actionItemId]);

  useEffect(() => {
    refreshData();
  }, [refreshData, actionItemId]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const history = useHistory();
  const onMoveAITable = () => {
    history.push({
      pathname: `${AIRoutes.root}`,
    });
  };

  const onMoveTopAI = () => {
    if (data?.parent) {
      history.push({
        pathname: `${AIRoutes.root}/detail/${data.parent.id}`,
      });
    }
  };
  return (
    <>
      {data && (
        <Grid container item xs={12}>
          <Grid container item xs={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <StyledBreadcrumb
                onClick={onMoveAITable}
                label={<span style={{ fontSize: "16px" }}>A/I 조회</span>}
                icon={<HomeIcon />}
              />
              {data.parent && (
                <StyledBreadcrumb
                  onClick={onMoveTopAI}
                  label={
                    <span style={{ fontSize: "16px" }}>
                      {data.parent.title}
                    </span>
                  }
                  icon={<ArrowCircleUpIcon />}
                />
              )}
              <Chip
                label={<span style={{ fontSize: "16px" }}>{data.title}</span>}
                style={{ backgroundColor: grey[100] }}
              />
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} mt="10px">
            <Tabs
              value={value}
              textColor="inherit"
              TabIndicatorProps={{
                style: { background: Brand600 },
              }}
              onChange={handleChange}
              style={{ marginBottom: "10px" }}
            >
              <Tab
                label={<span style={{ fontSize: "16px" }}>상세</span>}
                value={1}
              />
              <Tab
                label={<span style={{ fontSize: "16px" }}>미팅노트</span>}
                value={2}
              />
              <Tab
                label={<span style={{ fontSize: "16px" }}>보고서</span>}
                value={3}
              />
            </Tabs>
            <ContentScrollBox container item xs={12}>
              {value === 1 && (
                <ActionItemDetails data={data} refreshData={refreshData} />
              )}
              {value === 2 && <AIMeetingNote data={data} />}
              {value === 3 && <AIReport data={data} />}
            </ContentScrollBox>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ActionItemTabs;

const ContentScrollBox = styled(Grid)({
  height: "calc(100vh - 200px)",
  overflow: "scroll",
});

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
      color: Gray900,
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

import { Grid } from "@mui/material";
import {
  ActionItemDetailInfo,
  OrganizationInfo,
} from "../../system/types/ActionItem";
import AIDetailInfo from "./ActionItemDetails/AIDetailInfo";
import AIDetailChild from "./ActionItemDetails/AIDetailChild";
import AIDetailAttachment from "./ActionItemDetails/AIDetailAttachment";
import { CustomButton } from "../../styles/theme";
import { AIRoutes } from "../../system/types/type";
import { useHistory } from "react-router-dom";
import { ErrorDefault, SuccessDefault } from "../../styles/Colors";
import Swal from "sweetalert2";
import { ActionItemsApi } from "../../system/api/ActionItemsApi";
import AlertModal from "../Common/AlertModal";
import { ErrorHandler } from "../../system/ApiService";
import AIDetailComment from "./ActionItemDetails/AIDetailComment";
import { useEffect, useState } from "react";
import OrganizationUnitsApi from "../../system/api/OrganizationUnitApi";
import { useUserState } from "../../system/context/UserContext";
interface ActionItemDetailsProps {
  data: ActionItemDetailInfo;
  refreshData: () => void;
}
const leftBtn = { marginLeft: "10px" };
function ActionItemDetails({ data, refreshData }: ActionItemDetailsProps) {
  const user = useUserState();
  const checkIfManagerMatchesUser = (dt: ActionItemDetailInfo): boolean => {
    return dt.managers.some(
      (managerInfo) => managerInfo.manager.email === user.email
    );
  };

  const hasPermission =
    checkIfManagerMatchesUser(data) || user.roles.includes("ActionItem.admin");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var submitcheck = false;
  function onDelete() {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 A/I(Action Item)이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: SuccessDefault,
        cancelButtonColor: ErrorDefault,
      }).then((result) => {
        if (result.isConfirmed && data.id) {
          ActionItemsApi.deleteActionItem(data.id)
            .then(() => {
              AlertModal(
                "success",
                "선택하신 A/I(Action Item)이 삭제되었습니다."
              );
              history.push({
                pathname: `${AIRoutes.root}`,
              });
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  }
  const history = useHistory();
  const [teamData, setTeamData] = useState<OrganizationInfo[]>([]);
  useEffect(() => {
    OrganizationUnitsApi.getOrganizationUnitList()
      .then((res) => {
        const updatedData = res.data.map((item: OrganizationInfo) => {
          // Apply to level 0 items
          if (item.level === 0) {
            // Apply to children of level 0 items (level 1 items)
            const updatedChildren = item.children?.map(
              (child: OrganizationInfo) => ({
                ...child,
                expanded: true,
              })
            );
            return {
              ...item,
              htmlAttributes: { class: "e-disable" },
              expanded: true,
              // Update children if they exist
              children: updatedChildren || item.children,
            };
          }
          return item;
        });

        setTeamData(updatedData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const onPrintHandler = () => {
    const html = document.querySelector("html");
    const printContents = document.getElementById("download_area")?.innerHTML;
    const printDiv = document.createElement("DIV");

    if (html && printContents) {
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      document.body.style.display = "block";
      printDiv.style.display = "none";
    }
  };
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} justifyContent="right" alignItems="center">
        <CustomButton
          style={leftBtn}
          iconCss="e-icons e-download"
          onClick={onPrintHandler}
        >
          다운로드
        </CustomButton>
        {hasPermission && (
          <CustomButton
            style={leftBtn}
            iconCss="e-icons e-trash"
            onClick={onDelete}
          >
            삭제
          </CustomButton>
        )}
      </Grid>
      <Grid container item xs={12} id="download_area">
        <AIDetailInfo
          data={data}
          refreshData={refreshData}
          teamData={teamData}
          hasPermission={hasPermission}
        />
        <AIDetailChild
          data={data.children}
          refreshData={refreshData}
          parentId={data.id}
          teamData={teamData}
          triggeringReportId={data.triggeringReport.id}
          parentStatus={data.status}
          hasPermission={hasPermission}
        />
        <AIDetailAttachment
          data={data.attachmentFiles}
          id={data.id}
          refreshData={refreshData}
          hasPermission={hasPermission}
        />
        <AIDetailComment data={data} refreshData={refreshData} />
      </Grid>
    </Grid>
  );
}

export default ActionItemDetails;
